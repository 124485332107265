import { TIPOS_MOVIMIENTO_CAJA } from '@/utils/consts'

export default {
  async update (Vue, id, formData) {
    const importe = parseInt(formData.idtmovcaja) === TIPOS_MOVIMIENTO_CAJA.tipos.gastoManual ? formData.importe *-1 : formData.importe
    await Vue.$api.call(
      'movcaja.update',
      {
        values: {
          idmovcaja: id,
          idformapago: formData.formapago.idforma_pago,
          idcaja: formData.idcaja,
          idtmovcaja: formData.idtmovcaja,
          importe: importe,
          fmovimiento: formData.fmovimiento,
          concepto: formData.concepto,
          observaciones: formData.observaciones,
          idempleado: formData.idempleado,
          tipo: formData.tipo,
          idproyecto_contable: formData.idproyecto_contable,
          iddelegacion: formData.iddelegacion,
          estado: 1,
        },
      },
    )
  },
}
